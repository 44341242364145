import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cashier: "",

    cashierURL: "https://dog.agentone.virtualbet2.com/graphql",
    horseCashierURL: "https://horse.agentone.virtualbet2.com/graphql",
    //24681357 please delete this video from all video

    odds: [],
    HorseOdds: [],
    kenoTicketNumber: 0,
    kenoTicketNumberDate: "",
    HorseTicketNumber: 0,
    HorseTicketNumberDate: "",
  },
  getters: {},
  mutations: {
    loginCashier(state, cashier) {
      state.cashier = cashier;
    },

    odds(state, odds) {
      state.odds = odds;
    },
    HorseOdds(state, HorseOdds) {
      state.HorseOdds = HorseOdds;
    },

    kenoTicketNumberDate(state, kenoTicketNumberDate) {
      state.kenoTicketNumberDate = kenoTicketNumberDate;
    },

    kenoTicketNumber(state, kenoTicketNumber) {
      state.kenoTicketNumber = kenoTicketNumber;
    },

    HorseTicketNumber(state, HorseTicketNumber) {
      state.HorseTicketNumber = HorseTicketNumber;
    },

    HorseTicketNumberDate(state, HorseTicketNumberDate) {
      state.HorseTicketNumberDate = HorseTicketNumberDate;
    },
  },
  actions: {
    loginCashier(context, cashier) {
      context.commit("loginCashier", cashier);
    },

    odds(context, odds) {
      context.commit("odds", odds);
    },
    HorseOdds(context, HorseOdds) {
      context.commit("HorseOdds", HorseOdds);
    },

    kenoTicketNumberDate(context, kenoTicketNumberDate) {
      context.commit("kenoTicketNumberDate", kenoTicketNumberDate);
    },

    kenoTicketNumber(context, kenoTicketNumber) {
      context.commit("kenoTicketNumber", kenoTicketNumber);
    },

    HorseTicketNumber(context, HorseTicketNumber) {
      context.commit("HorseTicketNumber", HorseTicketNumber);
    },
    HorseTicketNumberDate(context, HorseTicketNumberDate) {
      context.commit("HorseTicketNumberDate", HorseTicketNumberDate);
    },
  },

  plugins: [createPersistedState()],
  modules: {},
});
